/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
   padding: 1rem;
   width:100%;
}

td.log_info {
   vertical-align: top;
   padding-right: 1rem;
   font-weight: bold;
   border-bottom: 1px solid lightsalmon;
}

td.log_message {
   vertical-align: top;
   white-space: pre-wrap;
   word-break: break-word;
   font-family: Consolas,monospace;
   font-size: 0.8rem;
   padding-right: 1rem;
   border-bottom: 1px solid lightsalmon;
}
td.last_row {
   border-bottom: 0 !important;
}
